.Links__outer {
  display: flex;
  justify-content: center;
  position: relative;
}

.Links__outer a {
  text-decoration: underline;
}

.Links__list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
  text-align: center;
}

.Links__inner {
  align-items: center;
  display: flex;
  gap: 24px;
  padding: 24px;
  max-width: 1000px;
}

.Links__inner h2 {
  font-weight: 600;
  font-size: 1.2rem;
}
